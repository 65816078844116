<template>
  <div>
    <b-row>
      <b-col md="1" />
      <b-col md="10" class="mb-2">
        <b-card border-variant="light">
          <b-form-group
            id="input-group-name"
            label-cols="2"
            label-align="right"
            content-cols="6"
            invalid-feedback="Search for patient by name/email or phone"
            :state="!(!lookupBy || lookupBy.length === 0)"
          >
            <template #label>
              <b> Lookup: </b>
            </template>
            <div class="input-group">
              <b-form-input id="input-name" maxlength="250" v-model="lookupBy" type="search" required></b-form-input>
              <div class="input-group-append">
                <b-button variant="primary" class="btn-icon" @click="doTheLookup" id="lookup-button">
                  <b-spinner v-if="loading" small style="margin-bottom: 3px" />
                  <feather-icon icon="UsersIcon" v-if="!loading" />
                </b-button>
                <b-tooltip target="lookup-button" variant="primary">Click to lookup patient data</b-tooltip>
              </div>
            </div>
          </b-form-group>

          <b-overlay :show="loading" rounded="sm" opacity="0.8">
            <b-table responsive :fields="fields" :items="patients" v-if="patients && patients.length > 0">
              <template #cell(fullName)="data">
                <b-link @click="customerDetails(data)">
                  {{ data.value ? data.value : "Name not set" }}
                </b-link>
              </template>
              <template #cell(profile)="data">
                <span>
                  <small>
                    Questions :
                    <template v-if="data.item.hasQuestions">
                      <feather-icon icon="CheckCircleIcon" class="text-success mr-1" />
                    </template>
                    <template v-else>
                      <feather-icon icon="XCircleIcon" class="text-danger mr-1" />
                    </template>
                  </small>
                </span>
                <br />
                <span>
                  <small>
                    Scanned ID:
                    <template v-if="data.item.hasScannedId">
                      <feather-icon icon="CheckCircleIcon" class="text-success mr-1" />
                    </template>
                    <template v-else>
                      <feather-icon icon="XCircleIcon" class="text-danger mr-1" />
                    </template>
                  </small>
                </span>
                <br />
                <span>
                  <small>
                    Address:
                    <template v-if="data.item.hasAddress">
                      <feather-icon icon="CheckCircleIcon" class="text-success mr-1" />
                    </template>
                    <template v-else>
                      <feather-icon icon="XCircleIcon" class="text-danger mr-1" />
                    </template>
                  </small>
                </span>
                <br />
                <span>
                  <small>
                    Contact:
                    <template v-if="data.item.hasContact">
                      <feather-icon icon="CheckCircleIcon" class="text-success mr-1" />
                    </template>
                    <template v-else>
                      <feather-icon icon="XCircleIcon" class="text-danger mr-1" />
                    </template>
                  </small>
                </span>
                <br />
                <span>
                  <small>
                    Releases:
                    <template v-if="data.item.hasReleases">
                      <feather-icon icon="CheckCircleIcon" class="text-success mr-1" />
                    </template>
                    <template v-else>
                      <feather-icon icon="XCircleIcon" class="text-danger mr-1" />
                    </template>
                  </small>
                </span>
              </template>
              <template #cell(username)="data">
                {{ data.value }}<br />
                <small>Created: {{ showDateInFormat(data.item.created) }}</small>
              </template>
              <template #cell(lastLogin)="data">
                {{ data.value ? showDateInFormat(data.value) : "Never" }}
              </template>
              <template #cell(documentCount)="data">
                {{ data.value }}
              </template>
              <template #cell(validated)="data">
                <template v-if="data.value">
                  <feather-icon icon="CheckCircleIcon" class="text-success mr-1" size="26" v-b-tooltip.hover.v-success.right title="Validated" />
                </template>
                <template v-else>
                  <feather-icon icon="XCircleIcon" class="text-danger mr-1" size="26" v-b-tooltip.hover.v-danger.right title="Not Validated" />
                </template>
              </template>
            </b-table>
          </b-overlay>
          <b-modal id="modalPopover" centered header-bg-variant="warning" busy v-model="getAuditReason" title="Reason for viewing information" hide-header-close>
            <p>
              You are viewing confidential information for {{ currentrow && currentrow.item ? currentrow.item.username : "(name not available)" }} This action will be audited and
              logged.
            </p>
            <b-form-group id="input-group-audit-reason" label-cols="2" label-align="right">
              <template #label>
                <b>Reason:</b>
              </template>
              <b-form-input id="input-auditreason" maxlength="200" v-model="auditreason" required></b-form-input>
              <small class="text-danger">Reason for viewing data is required</small>
            </b-form-group>
            <template #modal-footer>
              <b-button size="sm" variant="danger" @click="cancelView()"> Cancel </b-button>
              <b-button size="sm" variant="outline-warning" @click="viewCompassionPatient(currentrow, auditreason)"> View </b-button>
            </template>
          </b-modal>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { FormWizard, TabContent, WizardButton } from "vue-form-wizard";
import { uploadDocument, lookupPatient } from "./onboardingSteps";
import { getUserData } from "@/auth/utils";
import vSelect from "vue-select";
import store from "@/store";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import "vue-form-wizard/dist/vue-form-wizard.min.css";
import {
  BRow,
  BCol,
  BFormGroup,
  BFormRow,
  BFormInput,
  BFormCheckbox,
  BFormInvalidFeedback,
  BCard,
  BCardText,
  BAlert,
  BFormCheckboxGroup,
  BImg,
  BCardGroup,
  BOverlay,
  BButton,
  BFormFile,
  BCardTitle,
  BSpinner,
  BFormDatepicker
} from "bootstrap-vue";
import { required, email } from "@validations";
import moment from "moment";

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    FormWizard,
    WizardButton,
    TabContent,
    BRow,
    BCol,
    BImg,
    BOverlay,
    BFormCheckbox,
    BCardGroup,
    BFormGroup,
    BFormInput,
    vSelect,
    BCard,
    BCardText,
    BFormInvalidFeedback,
    BFormCheckboxGroup,
    BAlert,
    BButton,
    BFormFile,
    BFormRow,
    BCardTitle,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
    BSpinner,
    BFormDatepicker
  },
  data() {
    return {
      lookupBy: null,
      required,
      email,
      uploading: 0,
      loading: false,
      stopSave: false,
      patients: [],
      currentrow: null,
      getAuditReason: false,
      auditreason: "Checking document expiration",
      fields: [
        // 'mark_resolved',
        {
          key: "fullName",
          label: "Name"
        },
        "username",
        { key: "profile", label: "Profile" },
        { key: "documentCount", label: "Document Count", thClass: "text-right", tdClass: "text-right" },
        "validated"
      ]
    };
  },

  computed: {
    validateDocCheck() {
      return true;
      //TODO:
    },
    isUserAdmin() {
      return getUserData && getUserData.admin;
    }
  },
  methods: {
    viewCompassionPatient(row, reason) {
      console.log("pushing", row.item);
      this.$router.push({ name: "compassion-details-operator", params: { bareCustomer: row.item, auditReason: reason, isOperator: true } });
    },
    cancelView() {
      this.getAuditReason = false;
      this.currentrow = null;
    },
    customerDetails(row) {
      //get audit reason
      this.currentrow = row;
      this.getAuditReason = true;
    },
    showDateInFormat(date) {
      if (!date) {
        return "";
      }
      return moment(date).format("YYYY-MM-DD hh:mm:ss a");
    },
    async doTheLookup() {
      if (!this.lookupBy) {
        return;
      }
      let req = {};
      req.lookupBy = this.lookupBy;
      try {
        const { data } = await lookupPatient(req);
        console.log("got ", data.patients);
        this.patients.splice(0, this.patients.length);
        this.patients.push(...data.patients);
      } catch (err) {
        console.log(err);
        console.log(err.response);
      }
    },
    resetForm() {
      this.$refs.mainForm.reset();

      this.fullname = null;
      this.email = null;
      this.phone = null;
    }
  },
  mounted() {
    const usr = store.getters.getAuthUser;
    if (!usr.compassiongroup) {
      this.operatorName = usr.fullname;
      this.operatorEmail = usr.email;
      this.operatorPhone = usr.phone;
    }
  }
};
</script>
